.App {
  text-align: center;
  line-height: 1.6;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Markdown {
  text-align: left;
  padding: 0 5%;
  max-width: 1000px;
  margin: auto;
}

.markdown-iframe {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.markdown-img {
  display: block;
  width: auto;
  height: auto;
  max-width: 90%;
  max-height: 50vh;
  margin: auto;
  padding-bottom: 10px;
}

table {
  margin-bottom: 30px;
  max-width: 90%;
  text-align: left;
  color: #3f3f3f;
  border-collapse: collapse;
  border: 1px solid #e8e8e8
}

table tr:nth-child(even) {
  background-color: #f7f7f7
}

table th,table td {
  padding: 10px 15px
}

table th {
  background-color: #f0f0f0;
  border: 1px solid #dedede;
  border-bottom-color: #c9c9c9
}

table td {
  border: 1px solid #e8e8e8
}

blockquote {
  display: block;
  margin-left: 0;
  margin-right: 0;
  color: #828282;
  border-left: 4px solid #e8e8e8;
  padding-left: 15px;
  font-size: 18px;
  letter-spacing: -1px;
  font-style: italic
}

blockquote>:last-child {
  margin-bottom: 0
}

.code-inline {
  font-family: monospace;
  font-size: 15px;
  padding: 1px 5px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  background-color: #eef
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
